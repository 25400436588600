@import '~react-reflex/styles.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
  overflow: hidden;
  display: flex;
  aside {
    position: relative;

    .ant-layout-sider-children {
      display: flex;
      overflow: hidden;
    }

    .ant-menu-item {
      padding: 0 5px !important;
    }
  }

  .ant-layout .ant-layout {
    padding: 10px;

    main {
      display: flex;
      flex-direction: column;
      flex: 1;

      div.ant-tabs {
        flex: 1;

        .ant-tabs-nav {
          margin: 0;

          .ant-tabs-tab {
            padding: 8px 12px;
          }
        }
        .ant-tabs-content-holder {
          padding: 0 10px;
          border: solid 1px #eee;
          border-top: none;
          background: white;
          position: relative;
          .ant-tabs-content {
            display: flex;
            padding: 10px;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            overflow: auto;

            .ant-tabs-tabpane {
              display: flex;
              flex: 1
            }

            .ant-tabs-tabpane-hidden {
              display: none;
            }
          }
        }
      }
    }
  }
}

.list-item {
  display: flex;
  gap: 5px;
  max-width: 600px;

  .title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .delete {
    visibility: hidden;
  }

  &:hover {
    .delete {
      visibility: visible;
      &:hover {
        filter: drop-shadow(0 0 2px rgb(255 255 255/1));
        color: #1fa7f8;
      }
    }
  }
}

.dot-flashing {
  display: inline-block;
  margin: 0 5px;
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 2px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

@keyframes pulse {
  0% {
    background-size: 80% 80%;
  }
  50% {
    background-size: 200% 200%;
  }
  100% {
    background-size: 80% 80%;
  }
}
.ant-input-disabled {
  color: lighten(black, 40%); // Adjust the percentage as needed
  background-color: lighten(#f5f5f5, 10%); // Adjust the percentage as needed
}

.recording {
  animation: pulse 2s infinite;
  background-size: 80% 80%;
  background-position: center;
  background-image: radial-gradient(circle at center, rgba(0, 123, 255, 1) 0%, rgba(0, 123, 255, 0) 55%);
}

.highlighted {
  position: relative;  /* Required to position the tooltip */
  display: inline-block; /* Required for the tooltip to appear correctly */
}

//.highlighted:hover::after {
//  content: attr(data-tooltip); /* Retrieve tooltip content from data-tooltip attribute */
//  position: absolute;
//  bottom: 100%; /* Position tooltip above the highlighted text */
//  left: 50%;
//  transform: translateX(-50%); /* Center the tooltip */
//  padding: 4px 8px;
//  border: 1px solid #333;
//  background-color: #fff;
//  color: #333;
//  font-size: 12px;
//  pointer-events: none; /* Ensure the tooltip does not interfere with other interactions */
//  z-index: 100;
//  margin-bottom: 5px; /* Small space between tooltip and text */
//  border-radius: 4px;
//}

.permanent-highlight {
  position: relative;  /* Required to position the tooltip */
  display: inline; /* Required for the tooltip to appear correctly */
}
.permanent-highlight:hover:not([data-tooltip=""])::after{
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%; /* Position tooltip above the highlighted text */
  left: 50%;
  transform: translateX(-50%); /* Center the tooltip */
  padding: 4px 8px;
  border: 1px solid #333;
  background-color: #fff;
  color: #333;
  font-size: 12px;
  pointer-events: none; /* Ensure the tooltip does not interfere with other interactions */
  z-index: 100;
  margin-bottom: 5px; /* Small space between tooltip and text */
  border-radius: 4px;
}

.tab-content > div {
  width: 100%;
  height: 100%;
}

.dot-flashing::before {
  left: -9px;
  width: 5px;
  height: 5px;
  border-radius: 2px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 9px;
  width: 5px;
  height: 5px;
  border-radius: 2px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

.profile-menu > div.ant-menu-submenu-title {
  display: flex;
  align-items: center;
  padding: 0;
}

.current {
  background: #3367d1;
  color: white
}

.comment {
  background: rgba(139, 185, 248, 0.66);
  cursor: pointer;
}

.new-case-button {
  .ant-btn-compact-first-item {
    flex: 1;

  }

  .ant-btn-compact-item {
    height: 40px;
  }

  display: flex;
  width: 100%;
  background-color: #1677ff;
  color: #1677ff;
}

#root aside .ant-menu-item.new-list-item {
  padding: 0 !important
}

.main-panel h1 {
  font-size: 1.17em;
}

.main-panel h2 {
  font-size: 1em;
}

.new-case-page-container {
  //padding-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.new-case-page-company-logo {
  max-width: 270px;
}

.new-case-page-buttons-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates two columns of equal width */
  justify-content: center;
  align-items: start;
  margin-top: 20px;

}


.new-case-page-custom-button {
  margin: 5px;
  max-width: 400px;
  text-align: left;
  flex: 1;
  display: flex;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  transition: background-color 0.3s;
}

.new-case-page-custom-button:hover {
  background-color: rgba(224, 224, 224, 0.4);
}

.new-case-page-bold-text {
  font-weight: bold;
  margin-bottom: 4px; /* Spacing between bold and light text */
}

.new-case-page-light-text {
  white-space: break-spaces;
  opacity: 0.7;
  word-break: break-word !important; /* Breaks long words to prevent overflow */
  text-align: left;
  flex-shrink: 0;
}

.circular-popover {
  border-radius: 50%; /* Circular shape */
  overflow: hidden; /* Ensures the content does not overflow the circular shape */
  padding: 10px; /* Adjust padding as needed */
  /* Add other styling as needed */
}

.ant-menu-dark.ant-menu-inline .new-list-item.ant-menu-item-selected {
  background-color: transparent; /* or any color you want to retain */
}

.button-highlightable-markdown-normal {
  opacity: 0.75;
}

.button-highlightable-markdown-normal:hover {
  opacity: 1;
}

.commentMenuCard {
  position: relative;
}

.deleteIconCommentMenu {
  visibility: hidden;
}

.commentMenuCard:hover .deleteIconCommentMenu {
  visibility: visible;
}

.delete-file-icon {
  opacity: 0;
  color: #fd4343;
  transition: opacity 0.3s ease;
}

.ant-upload {
  padding: 0 !important;
}

.rsc-content {
  height: calc(100vw - 525px) !important;
}

.delete-file-icon:hover {
  opacity: 1;
}

.hQsUiY {
  background: #E5E5EA !important;
  color: black !important;
}

.iOCYje {
  background: #007AFF !important;
  color: white !important;
}

.dPdbIW {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
}

.glfuN {
  background: #E5E5EA !important;
}


.rsc {
  display: flex !important;
  height: 100% !important;
  flex-direction: column;
}
